import { Table, Pagination, Image, Empty} from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../component/SearchProps";
import dayjs from "dayjs";
import { FormatVND } from "../../utils/format";
import { orderStatus, payment } from "../../utils/constants";
import { useDevice } from "../../hooks";


export default function TableOrder({order, pagination, setPagination}) {
    const { isMobile } = useDevice();
    
    const columns = [
        {
            title: <div>ID</div>,
            key: "order_id",
            dataIndex: "order_id",
            sorter: (a, b) => a.order_id - b.order_id,
            render: (_, record) => <div>{record?.order_id}</div>,
        },
        {
            title: <div>Sản phẩm</div>,
            key: "orderDt",
            width: 400,
            dataIndex: "orderDt",
            render: (_, record) => <div>
                {record?.orderDt?.map((item, i) => {
                    return (
                        <div className={`flex items-center py-2 justify-between ${(i === 0 ? 'border-y' : 'border-b')}`}>
                            <div>
                                <p>{item?.product_name?.slice(0, 40)}...</p>
                                <p>{item?.description}</p>
                                <p>{FormatVND(item?.price)}</p>
                            </div>
                            <p>
                                <Image
                                    src={JSON.parse(item?.product_image)?.filter((img) => img.type === 'image')?.[0]?.data}
                                    width={60}
                                    height={60}
                                />
                            </p>
                        </div>
                    );
                })}
            </div>,
        },
        {
          title: <div>Địa chỉ người nhận</div>,
          key: "to_address",
          dataIndex: "to_address",
          ...SearchProps("to_address"),
          render: (_, record) => <div>{record?.to_address}</div>,
        },
        {
            title: <div>Tổng tiền</div>,
            key: "total_price",
            dataIndex: "total_price",
            sorter: (a, b) => a.total_price - b.total_price,
            render: (_, record) => <div>{FormatVND(record?.total_price)}</div>,
        },
        {
            title: <div>Hình thức thanh toán</div>,
            key: "payment",
            dataIndex: "payment",
            render: (_, record) => <div>{payment.find(method => method.value === record?.payment)?.label}</div>,
        },
        {
            title: <div>Trạng thái</div>,
            key: "status",
            dataIndex: "status",
            render: (_, record) => <div>
                {orderStatus?.map((_,i) => (
                    <>
                        {_?.id == record?.status && (
                            <p className={_?.color}>{_?.data}</p>
                        )}
                    </>
                ))}
            </div>,
        },
        {
            title: <div className={"base-table-cell-label "}>Ngày mua</div>,
            key: "create_at",
            dataIndex: "create_at",
            sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
            render: (_, record) => {
                return (
                <div className={"cursor-pointer text-[14px] font-normal"}>
                    <span className={"!inline-block min-w-[100px]"}>
                    {dayjs(record?.create_at).format("DD/MM/YYYY")}
                    </span>
                </div>
                );
            },
        }
    ];

    return (
        <div className="bg-white p-5">
            <h2 className="text-center font-semibold text-xl">Đơn hàng của tôi</h2>
            <div className="w-full h-full mt-5 pb-20 relative">
                {order ? (
                    <>
                        {isMobile ? (
                            <>
                                {order?.data?.map((_,i) => (
                                    <div className="my-5 p-2 bg-gray-100 border-y">
                                        <p>Họ và tên: <span className="font-semibold">{_?.fullname}</span></p>
                                        <p>Email: <span className="font-semibold">{_?.email}</span></p>
                                        <p>Trạng thái đơn hàng: 
                                            {orderStatus?.map((r,i) => (
                                                <>
                                                    {r?.id == _?.status && (
                                                        <span className={`${r?.color} pl-2`}>{r?.data}</span>
                                                    )}
                                                </>
                                            ))}
                                        </p>
                                        <p>Ngày mua: <span className="font-semibold">{dayjs(_?.create_at).format("DD/MM/YYYY")}</span></p>
                                        <div>
                                            {_?.orderDt?.map((item, i) => {
                                                return (
                                                    <div className={`flex items-center py-2 justify-between ${(i === 0 ? 'border-y' : 'border-b')}`}>
                                                        <div>
                                                            <p>{item?.product_name?.slice(0, 40)}...</p>
                                                            <p>{item?.description}</p>
                                                            <p>Số lượng: {item?.quantity}</p>
                                                            <p className="font-semibold text-red-600">{FormatVND(item?.price)}</p>
                                                        </div>
                                                        <p>
                                                            <Image
                                                                src={JSON.parse(item?.product_image)?.filter((img) => img.type === 'image')?.[0]?.data}
                                                                width={60}
                                                                height={60}
                                                            />
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <p>Tổng tiền: <span className="font-semibold text-red-600">{FormatVND(_?.total_price)}</span></p>
                                        <div>
                                            <p>{_?.product_name}</p>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <Table
                                className={"custom-table"}
                                dataSource={order?.data}
                                columns={columns}
                                pagination={false}
                            />
                        )}
                    </>
                ) : <Empty />}
                <Pagination
                    className="flex justify-center absolute inset-x-0 bottom-0"
                    current={pagination.page}
                    total={order?.total}
                    pageSize={pagination.pageSize}
                    showSizeChanger
                    onChange={(p, ps)=> {
                        setPagination({
                            page: p,
                            pageSize: ps
                        })
                    }}
                />
            </div>
        </div>
    )
}