import { Table, message, Button, Row, Col, Pagination, Image, Rate } from "antd";
import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";

export default function CommentManage() {
  const [comment, setComment] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const fetchComment = async () => {
    await axiosInstance.get(`/comment/getAll`, {params: pagination})
      .then((res) => {
        const data = res?.data;
        setComment(data);
      })
      .catch(() => message.error("Error server!"));
  };

  const handleDelete = async (comment_id) => {
    await axiosInstance.delete(`/comment/delete/` + comment_id)
      .then((res) => {
        const data = res?.data;
        message.success(data?.message);
        fetchComment();
      })
      .catch(() => message.error("Error server!"));
  }

  useEffect(() => {
    fetchComment();
  }, [pagination]);

  const columns = [
    {
      title: <div>ID</div>,
      key: "comment_id",
      dataIndex: "comment_id",
      width: 100,
      sorter: (a, b) => a.comment_id - b.comment_id,
      render: (_, record) => <div>{record?.comment_id}</div>,
    },
    {
      title: <div>Người đăng</div>,
      key: "fullname",
      dataIndex: "fullname",
      width: 150,
      render: (_, record) => <div>{record?.user_id} - {record?.fullname}</div>,
    },
    {
      title: <div>Bình luận</div>,
      key: "comment_content",
      dataIndex: "comment_content",
      width: 150,
      ...SearchProps("comment_content"),
      render: (_, record) => <div>{record?.comment_content}</div>,
    },
    {
      title: <div>Sản phẩm</div>,
      key: "product_name",
      dataIndex: "product_name",
      width: 150,
      ...SearchProps("product_name"),
      render: (_, record) => <div>{record?.product_name}</div>,
    },
    {
      title: <div>Sao</div>,
      key: "comment_rating",
      dataIndex: "comment_rating",
      width: 150,
      render: (_, record) => <div><Rate value={record?.comment_rating} disabled/></div>,
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
      key: "create_at",
      dataIndex: "create_at",
      width: 100,
      sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
      render: (_, record) => {
        return (
          <div className={"cursor-pointer text-[14px] font-normal"}>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.create_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      key: "operation",
      dataIndex: "operation",
      width: 50,
      render: (_, record) => {
        return (
          <DeleteFilled className="text-red-600" onClick={() => handleDelete(record?.comment_id)} />
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Row gutter={10} className={"mb-[8px]"}>
          <Col flex={1}>
            <div className={"text-[20px] font-medium"}>Quản lý comment</div>
          </Col>
        </Row>
      </div>
      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={comment?.data}
          columns={columns}
          pagination={false}
        />
        <Pagination
          className="flex justify-center absolute inset-x-0 bottom-10"
          current={pagination.page}
          total={comment?.total}
          pageSize={pagination.pageSize}
          showSizeChanger
          onChange={(p, ps)=> {
            setPagination({
              page: p,
              pageSize: ps
            })
          }}
        />
      </div>
    </>
  );
}
