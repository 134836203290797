import { Table, message, DatePicker, Row, Col, Pagination, Image, Space, Modal, Select, Card } from "antd";
import { EditOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";
import { FormatVND } from "../../../utils/format";
import { orderStatus, payment } from "../../../utils/constants";
import { checkSameDate } from "../../../helper";
const { RangePicker } = DatePicker;

export default function OrderManage() {
  const now = new Date();
  const [order, setOrder] = useState([]);
  const [total, setTotal] = useState();
  const [date, setDate] = useState({
    fromDate: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-01`,
    toDate: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate()}`
  });
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const fetchOrder = async () => {
    await axiosInstance.get(`/order/getAll`, {params: { ...pagination, ...date }})
      .then((res) => {
        const data = res?.data;
        setOrder(data);
        setTotal(data?.totalStatus);
      })
      .catch(() => message.error("Error server!"));
  };

  const updateStatusOrder = async (value, order_id) => {
    await axiosInstance.post(`/order/updateByStatus/${order_id}`, {
      status: value
    })
      .then((res) => {
        const data = res?.data;
        fetchOrder();
        message.success(String(data?.message));
        window.location.reload();
      })
      .catch(() => message.error("Error server!"));
  };

  const orderDetail = (value) => {
    {value && (
        Modal.confirm({
            title: 'Chi tiết đơn hàng',
            icon: <ExclamationCircleOutlined />,
            width: 1200,
            okText: 'ok',
            content: (
                <div>
                    <div className="flex justify-between">
                      <p className="font-semibold text-xl">Thông tin đặt hàng</p>
                      {value?.status === 0 ? 
                        <p className="font-semibold text-xl text-red-500">Chưa xác nhận</p> : 
                        <p className="font-semibold text-xl text-green-500">Đã xác nhận</p>
                      }
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-5 text-lg">
                      <p><span className="font-semibold">Đơn hàng: </span>{value?.order_id}</p>
                      <p><span className="font-semibold">Họ và tên: </span>{value?.fullname}</p>
                      <p><span className="font-semibold">Email: </span>{value?.email}</p>
                      <p><span className="font-semibold">Số điện thoại: </span>{value?.phone}</p>
                      <p><span className="font-semibold">Địa chỉ gửi hàng: </span>{value?.from_address}</p>
                      <p><span className="font-semibold">Địa chỉ nhận hàng: </span>{value?.to_address}</p>
                      <p><span className="font-semibold">
                        Mã voucher: </span>{value?.voucher_id ? value?.voucher_code + " - " + FormatVND(value?.voucher_price) : "Không có voucher"}
                      </p>
                      <p><span className="font-semibold">Phí ship: </span>{FormatVND(value?.fee_ship)}</p>
                      <p className="col-span-2"><span className="font-semibold">Tài khoản đặt hàng: </span>{value?.userFullname} - {value?.userEmail}</p>
                    </div>
                    
                    <div className="pt-5 pb-10">
                      <p className="font-semibold text-xl">Thông tin thanh toán</p>
                      <p className="col-span-2 text-lg pt-5"><span className="font-semibold">Hình thức thanh toán: </span> {payment.find(method => method.value === value?.payment)?.label}</p>
                      {value?.payment != 1 && (
                        <div className="grid grid-cols-2 gap-4 text-lg">
                          <p className="flex items-center">
                            <span className="font-semibold pr-2">Nội dung chuyển tiền: </span>{value?.transfer_content ? value?.transfer_content : "Trống"}
                          </p>
                          <p className="flex items-center">
                            <span className="font-semibold pr-2">Ảnh chuyển tiền: </span>{value?.transfer_image ? <Image width={80} src={value?.transfer_image}/> : "Trống"}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="grid grid-cols-6 gap-4 border-b pb-2 text-center font-semibold">
                      <p>ID</p>
                      <p>Tên sản phẩm</p>
                      <p>Ảnh</p>
                      <p>Mô tả</p>
                      <p>Số lượng</p>
                      <p>Giá tiền</p>
                    </div>
                    <div className="grid grid-cols-6 gap-4 py-5 text-center">
                        {value?.orderDt?.map((_,i) => (
                          <>
                            <p>{_?.order_detail_id}</p>
                            <p>{_?.product_name}</p>
                            <p className="flex justify-center">
                              <Image src={JSON.parse(_?.product_image)?.filter((img) => img.type === 'image')?.[0]?.data} width={100}/>
                            </p>
                            <p>{_?.description}</p>
                            <p>{_?.quantity}</p>
                            <p>{FormatVND(_?.price)}</p>
                          </>
                        ))}
                    </div>
                    <div className="flex justify-between px-10 py-2 text-lg font-semibold bg-gray-100">
                      <p>Tổng tiền:</p>
                      <p className="text-red-500">{FormatVND(value?.total_price)}</p>
                    </div>
                </div>
            ),
        })
    )}
  };

  useEffect(() => {
    fetchOrder();
  }, [pagination, date]);

  const columns = [
    {
      title: <div>ID</div>,
      key: "order_id",
      dataIndex: "order_id",
      sorter: (a, b) => a.order_id - b.order_id,
      render: (_, record) => <div>{record?.order_id}</div>,
    },
    {
      title: <div>Tên khách hàng</div>,
      key: "fullname",
      dataIndex: "fullname",
      ...SearchProps("fullname"),
      render: (_, record) => 
        <div>
          {orderStatus?.map((_,i) => (
            <>
              {_?.id == record?.status && (
                  <p className={_?.color}>{record?.fullname}</p>
              )}
            </>
          ))}
        </div>,
    },
    {
      title: <div>Email</div>,
      key: "email",
      dataIndex: "email",
      ...SearchProps("email"),
      render: (_, record) => 
        <div>
          {orderStatus?.map((_,i) => (
            <>
              {_?.id == record?.status && (
                  <p className={_?.color}>{record?.email}</p>
              )}
            </>
          ))}
        </div>,
    },
    {
      title: <div>Số điện thoại</div>,
      key: "phone",
      dataIndex: "phone",
      ...SearchProps("phone"),
      render: (_, record) => 
        <div>
          {orderStatus?.map((_,i) => (
            <>
              {_?.id == record?.status && (
                  <p className={_?.color}>{record?.phone}</p>
              )}
            </>
          ))}
        </div>,
    },
    {
      title: <div>Địa chỉ người nhận</div>,
      key: "to_address",
      dataIndex: "to_address",
      ...SearchProps("to_address"),
      render: (_, record) => 
        <div>
          {orderStatus?.map((_,i) => (
            <>
              {_?.id == record?.status && (
                  <p className={_?.color}>{record?.to_address}</p>
              )}
            </>
          ))}
        </div>,
    },
    {
      title: <div>Tổng tiền</div>,
      key: "total_price",
      dataIndex: "total_price",
      sorter: (a, b) => a.total_price - b.total_price,
      render: (_, record) => 
        <div>
          {orderStatus?.map((_,i) => (
            <>
              {_?.id == record?.status && (
                  <p className={_?.color}>{FormatVND(record?.total_price)}</p>
              )}
            </>
          ))}
        </div>,
    },
    {
      title: <div className={"base-table-cell-label"}>Trạng thái</div>,
      key: "status",
      dataIndex: "status",
      width: 280,
      filters: orderStatus.map(status => ({
        text: status.data,
        value: status.id,
      })),
      onFilter: (value, record) => {
        return record?.role === value;
      },
      render: (_, record) => {
        return (
          <div>
            <Select
              options={orderStatus.map(status => ({
                value: status.id,
                label: status.data,
              }))}
              className={"w-[200px]"}
              value={record?.status}
              defaultValue={record?.status}
              onChange={(value) => updateStatusOrder(value, record?.order_id)}
            />
          </div>
        );
      },
    },
    {
      title: <div>Tài khoản đặt</div>,
      key: "userFullname",
      dataIndex: "userFullname",
      ...SearchProps("userFullname"),
      render: (_, record) => 
        <div>
          {orderStatus?.map((_,i) => (
            <>
              {_?.id == record?.status && (
                <div>
                  <p className={_?.color}>{record?.userFullname}</p>
                  <p className={_?.color}>{record?.userEmail}</p>
              </div>
              )}
            </>
          ))}
        </div>,
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
      key: "create_at",
      dataIndex: "create_at",
      sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
      render: (_, record) => {
        return (
          <div className={"cursor-pointer text-[14px] font-normal"}>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.create_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      key: "operation",
      dataIndex: "operation",
      width: 50,
      render: (_, record) => {
        return (
            <Space>
                <InfoCircleOutlined onClick={()=>orderDetail(record)}/>
                {/* <Link
                    to={`/admin/quan-ly-don-hang/${record?.order_id}`}
                    className={"text-[var(--blue)]"}
                >
                    <EditOutlined />
                </Link> */}
            </Space>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Row gutter={10} className={"mb-[8px]"}>
          <Col flex={1}>
            <div className={"text-[20px] font-medium"}>Quản lý đơn hàng</div>
            <p>(Mặc định sẽ là tháng hiện tại)</p>
            <div className="flex justify-center">
              <RangePicker
                format={'YYYY-MM-DD'}
                onChange={(value, dateString) => {
                  console.log('Formatted Selected Time: ', dateString);
                  setDate({
                    fromDate: dateString?.[0],
                    toDate: dateString?.[1]
                  })
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Row className="pt-5">
        <Col xs={24} xl={6}>
          <div className="bg-slate-400 text-center p-2 mx-2 font-bold text-xl">
            <p>Số đơn hàng chưa xác nhận</p>
            {total?.[0]?.total ? total?.[0]?.total : 0}
          </div>
        </Col>
        <Col xs={24} xl={6}>
          <div className="bg-green-400 text-center p-2 mx-2 font-bold text-xl">
            <p>Số đơn hàng giao hàng thành công</p>
            {total?.[1]?.total ? total?.[1]?.total : 0}
          </div>
        </Col>
        <Col xs={24} xl={6}>
          <div className="bg-yellow-400 text-center p-2 mx-2 font-bold text-xl">
            <p>Số đơn hàng đã gửi hàng</p>
            {total?.[2]?.total ? total?.[2]?.total : 0}
          </div>
        </Col>
        <Col xs={24} xl={6}>
          <div className="bg-red-400 text-center p-2 mx-2 font-bold text-xl">
            <p>Số đơn hàng đã hủy đơn</p>
            {total?.[3]?.total ? total?.[3]?.total : 0}
          </div>
        </Col>
      </Row>
      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={order?.data}
          columns={columns}
          pagination={false}
        />
        <Pagination
          className="flex justify-center absolute inset-x-0 bottom-10"
          current={pagination.page}
          total={order?.total}
          pageSize={pagination.pageSize}
          showSizeChanger
          onChange={(p, ps)=> {
            setPagination({
              page: p,
              pageSize: ps
            })
          }}
        />
      </div>
    </>
  );
}
