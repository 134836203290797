import { useEffect, useState } from "react";
import PdfView from "../../component/pdfView";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Breadcrumb, Empty, message } from "antd";
import { useDevice } from "../../hooks";
import { getAllBlog, getBySlug } from "../../api/blog";
import parser from "html-react-parser";
import ItemBlog from "../../section/itemBlog";

export default function BlogDetail() {
    const param = useParams();
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    const [blog, setBlog] = useState([]);
    const [cookies] = useCookies(["user"]);
    const [allBlog, setAllBlog] = useState();
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 8,
      });

    useEffect(()=> {        
        const query = `page=${pagination?.page}&pageSize=${pagination?.pageSize}`;
        getAllBlog(query, setAllBlog);
    }, [pagination])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    useEffect(() => {
        getBySlug(param?.slug, setBlog, message);
    }, []);

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div>
                <Breadcrumb
                    items={[
                        {
                            title: 'Trang chủ',
                        },
                        {
                            title: <a href="/blog">Blog</a>,
                        },
                        {
                            title: <a href={`/blog/` + blog?.data?.[0]?.blog_slug}>{blog?.data?.[0]?.blog_name}</a>
                        },
                    ]}
                />
            </div>
            <div className="bg-white mt-5 p-10">
                <h1 className="text-2xl font-bold">{blog?.data?.[0]?.blog_name}</h1>
                <p className="truncate h-[45px] text-gray-500">{parser(String(blog?.data?.[0]?.blog_description)?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>"))}</p>         
            </div>
            <div className="py-5">
                <ItemBlog blog={allBlog?.data} />
            </div>
        </div>
    )
}