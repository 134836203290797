import { useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { useDevice } from "../../hooks";
import { fetchAll } from "../../api/iframePage";
import { Col, Row, Select, message } from "antd";
import { getAll } from '../../api/category';
import { fetchAllProductMkt } from '../../api/product';
import ItemProductGrid from '../../section/itemProductGrid';

export default function MoneyPage() {
    const { isMobile } = useDevice();
    const [data, setData] = useState([]);
    const [categoryChild, setCategoryChild] = useState([]);
    const [isFixed, setIsFixed] = useState(false);
    const [categoryMenu, setCategoryMenu] = useState([]);
    const [topBuy, setTopBuy] = useState(false);
    const [price, setPrice] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(10);

    useEffect(() => {
        fetchAll(setData);
        getAll("page=1&pageSize=100", setCategoryMenu);
    }, []);

    useEffect(() => {
        let query = `page=1&pageSize=${itemsToShow}`;
        if (topBuy) { query += `&topBuy=1`; }
        if (price > 0) { query += `&filPrice=${price}`; }
        fetchAllProductMkt(query, setCategoryChild, message);
    }, [itemsToShow, topBuy, price]);
    
    useEffect(() => {
        const handleScroll = () => {
            setIsFixed(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleLoadMore = () => {
        setItemsToShow(prev => prev + 10);
    };

    return (
        <div className="max-w-screen-2xl mx-auto">
            <Row>
                {!isMobile && (
                    <Col xs={24} xl={4} className='pr-2'>
                        <div 
                            className={`my-2 p-5 bg-white rounded-lg h-[100vh] ${isFixed ? "fixed top-0" : "overflow-y-visible"}`} 
                            style={{
                                scrollbarWidth: 'none'
                            }}
                        >
                            {categoryMenu?.data?.map((_,i)=> (
                                <a href={`/danh-muc-san-pham/${_?.category_slug}`} className='flex items-center p-2 hover:bg-gray-200 rounded-xl'>
                                    <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                    <p className='pl-2 text-black w-[150px]'>{_?.category_name}</p>
                                </a>
                            ))}
                        </div>
                    </Col>
                )}
                <Col xs={24} xl={isMobile ? 24 : 20}>
                    <div className={`${isMobile ? "mt-16" : "" } my-2 p-5 bg-white rounded-xl`}>
                        <h1 className='text-[28px] font-semibold'>Sản phẩm tiếp thị</h1>
                        <h2 className='text-[20px] text-gray-600 font-semibold'>Lưu ý: Vui lòng chọn vào nút nhận ngay để copy liên kết tiếp thị và chia sẻ nó để được nhận tiền hoa hồng!</h2>
                    </div>
                    
                    <div className='my-2 p-5 bg-white rounded-xl'>
                        <div className='flex items-center'>
                            {!isMobile && (
                                <p className='font-semibold'>Sắp xếp theo:</p>
                            )}
                            <div className='px-2'>
                                <button 
                                    className={`border px-4 py-1 mx-4 font-normal rounded-lg ${topBuy ? "bg-blue-500 text-white" : ""}`} 
                                    onClick={() => { setTopBuy(!topBuy); setPrice(0); }}
                                >
                                    Bán Chạy
                                </button>
                                <Select
                                    className="w-[180px]"
                                    onChange={(e) => { setPrice(e); setTopBuy(false); }}
                                    options={[
                                        { label: "Hoa hồng", value: 0 },
                                        { label: "Hoa hồng: Từ thấp đến cao", value: 1 },
                                        { label: "Hoa hồng: Từ cao đến thấp", value: 2 }
                                    ]}
                                    value={price}
                                />
                            </div>
                        </div>
                        <div className='pt-4 pb-10'>
                            <ItemProductGrid
                                product={categoryChild?.data?.slice(0, itemsToShow)} 
                                grid={isMobile ? 2 : 5} 
                                titleSize={13}
                                heightTitle={10}
                            />
                        </div>
                        {itemsToShow < categoryChild?.total && (
                            <button className='border px-4 py-2 font-semibold text-lg text-blue-600 border-blue-600 hover:bg-blue-100' onClick={handleLoadMore}>Xem thêm</button>
                        )}
                    </div>          
                </Col>
            </Row>
        </div>
    )
}