import { Table, Pagination, Image, Empty, Select, Modal, message, Input} from "antd";
import dayjs from "dayjs";
import { FormatVND } from "../../utils/format";
import { useDevice } from "../../hooks";
import { orderStatus } from "../../utils/constants";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";


export default function RowAffiliate({affiliate, pagination, setPagination}) {
    const { isMobile } = useDevice();
    const [cookies] = useCookies(["user"]);
    const [page, setPage] = useState(0);
    const [history, setHistory] = useState(0);
    const [request, setRequest] = useState({
        request_money: 0,
        stk: "",
        user_id: cookies?.user?.user_id
    })
    const [paginationRq, setPaginationRq] = useState({
        page: 1,
        pageSize: 10,
      });

    const getAllRequestMoneyByUser = async () => {
        await axiosInstance.get(`/affiliate/getAllRequestMoneyByUser/`+ cookies?.user?.user_id, {params: paginationRq})
          .then((res) => {
            const data = res?.data;
            setHistory(data);
          })
          .catch(() => message.error("Error server!"));
    }

    const handleOk = async () => {
        if(request?.request_money > 0 && request?.request_money <= cookies?.user?.wallet && request?.request_money >= 50000) {
            await axiosInstance.post(`/affiliate/createRequestMoney`, request)
                .then((res) => {
                    const data = res?.data;
                    message.info(String(data?.message));
                    setRequest((prevState) => ({
                        ...prevState,
                        request_money: 0,
                    }))
                    getAllRequestMoneyByUser();
                    setPage(0);
                })
                .catch(() => message.error("Error server!"));
        } else {
            if(request?.request_money == 0) {
                message.warning("Số tiền bạn muốn rút phải lớn hơn 0!");
            } else if (request?.request_money > cookies?.user?.wallet) {
                message.warning("Số tiền bạn muốn rút vượt quá số tiền trong ví!");
            } else if (request?.request_money >= 50000) {
                message.warning("Số tiền bạn muốn rút phải lớn hơn 50.000!");
            } else {
                message.warning("Số tiền bạn muốn rút không hợp lệ!");
            }
        }
    }
    
    const columns = [
        {
            title: <div>ID</div>,
            key: "refer_product_id",
            dataIndex: "refer_product_id",
            sorter: (a, b) => a.refer_product_id - b.refer_product_id,
            render: (_, record) => <div>{record?.refer_product_id}</div>,
        },
        {
            title: <div>Người nhập liên kết</div>,
            key: "user_id",
            dataIndex: "user_id",
            render: (_, record) => <div>{record?.user_id} - {record?.fullname}</div>,
        },
        {
            title: <div>Nội dung</div>,
            key: "status",
            dataIndex: "status",
            render: (_, record) => <div>
                {orderStatus?.map((_,i) => (
                    <>
                        {_?.id == record?.status && (
                            <> 
                                {record?.status === 1 ? (
                                    <p className={_?.color}>Đã nhận được {FormatVND((record?.product_price - (record?.product_price * (record?.product_discount/100))) * (record?.price_mkt/100))}</p>
                                ) : (
                                    <p className={_?.color}>Chưa được nhận {FormatVND((record?.product_price - (record?.product_price * (record?.product_discount/100))) * (record?.price_mkt/100))}</p>
                                )}
                            </>
                        )}
                    </>
                ))}
            </div>,
        },
        {
            title: <div>Trạng thái</div>,
            key: "status",
            dataIndex: "status",
            render: (_, record) => <div>
                {orderStatus?.map((_,i) => (
                    <>
                        {_?.id == record?.status && (
                            <p className={_?.color}>{_?.data}</p>
                        )}
                    </>
                ))}
            </div>,
        },
        {
            title: <div className={"base-table-cell-label "}>Ngày mua</div>,
            key: "create_at",
            dataIndex: "create_at",
            sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
            render: (_, record) => {
                return (
                <div className={"cursor-pointer text-[14px] font-normal"}>
                    <span className={"!inline-block min-w-[100px]"}>
                    {dayjs(record?.create_at).format("DD/MM/YYYY")}
                    </span>
                </div>
                );
            },
        }
    ];
    
    const columnHis = [
        {
            title: <div>ID</div>,
            key: "request_money_id",
            dataIndex: "request_money_id",
            sorter: (a, b) => a.request_money_id - b.request_money_id,
            render: (_, record) => <div>{record?.request_money_id}</div>,
        },
        {
            title: <div>Người yêu cầu</div>,
            key: "user_id",
            dataIndex: "user_id",
            render: (_, record) => <div>{record?.user_id} - {record?.fullname}</div>,
        },
        {
            title: <div>Số tiền yêu cầu</div>,
            key: "request_money",
            dataIndex: "request_money",
            render: (_, record) => <div>{FormatVND(record?.request_money)}</div>,
        },
        {
            title: <div>Số tài khoản nhận</div>,
            key: "stk",
            dataIndex: "stk",
            render: (_, record) => <div>{record?.stk}</div>,
        },
        {
            title: <div>Trạng thái</div>,
            key: "status",
            dataIndex: "status",
            render: (_, record) => <div>
                {
                    record?.status == 0 ? 
                        <p className="text-lg font-semibold text-gray-600">Đang chờ</p> : 
                    record?.status == 1 ?
                        <p className="text-lg font-semibold text-green-600">Đã thanh toán</p> :
                        <p className="text-lg font-semibold text-red-600">Đã từ chối</p>
                }
            </div>,
        },
        {
            title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
            key: "create_at",
            dataIndex: "create_at",
            sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
            render: (_, record) => {
                return (
                <div className={"cursor-pointer text-[14px] font-normal"}>
                    <span className={"!inline-block min-w-[100px]"}>
                    {dayjs(record?.create_at).format("DD/MM/YYYY")}
                    </span>
                </div>
                );
            },
        }
    ];

    useEffect(() => {
        if(cookies?.user) {
            getAllRequestMoneyByUser();
        }
    }, [cookies?.user])

    return (
        <div className="bg-white p-5">
            <div className="flex justify-between">
                <p className="text-lg font-semibold">Số dư của tôi: {FormatVND(cookies?.user?.wallet)}</p>
                <div className="flex items-center">
                    <p className={`${page === 2 ? "text-blue-600" : "text-black"} text-lg font-semibold cursor-pointer`} onClick={() => setPage(2)}>Rút tiền</p>
                    <p className="px-2 text-lg">|</p>
                    <p className={`${page === 1 ? "text-blue-600" : "text-black"} text-lg font-semibold cursor-pointer`} onClick={() => setPage(1)}>Lịch sử</p>
                </div>
            </div>

            {page == 0 && (
                <div>
                    <h2 className="text-center font-semibold text-xl">Các người dùng đã tiếp thị</h2>
                    
                    <div className={`grid w-full h-full mt-5 pb-20 relative`}>
                        {affiliate?.data?.length > 0 ? (
                            <Table
                                className={"custom-table"}
                                dataSource={affiliate?.data}
                                columns={columns}
                                pagination={false}
                            />
                        ) : (<Empty />)}
                        <Pagination
                            className="flex justify-center absolute inset-x-0 bottom-0"
                            current={pagination.page}
                            total={affiliate?.total}
                            pageSize={pagination.pageSize}
                            showSizeChanger
                            onChange={(p, ps)=> {
                                setPagination({
                                    page: p,
                                    pageSize: ps
                                })
                            }}
                        />
                    </div>
                </div>
            )}

            {page == 1 && (
                <div>
                    <h2 className="text-center font-semibold text-xl">Lịch sử yêu cầu rút tiền</h2>
                    
                    <div className={`grid w-full h-full mt-5 pb-20 relative`}>
                        {history?.data?.length > 0 ? (
                            <Table
                                className={"custom-table"}
                                dataSource={history?.data}
                                columns={columnHis}
                                pagination={false}
                            />
                        ) : (<Empty />)}
                        <Pagination
                            className="flex justify-center absolute inset-x-0 bottom-0"
                            current={pagination.page}
                            total={affiliate?.total}
                            pageSize={pagination.pageSize}
                            showSizeChanger
                            onChange={(p, ps)=> {
                                setPagination({
                                    page: p,
                                    pageSize: ps
                                })
                            }}
                        />
                    </div>
                </div>
            )}

            <Modal
                title="Tạo lệnh rút tiền"
                visible={page == 2}
                onOk={handleOk}
                onCancel={()=> setPage(0)}
            >
                <p>Lưu ý: Số tiền rút tối thiểu là {FormatVND(50000)}</p>
                <p className="pt-5 pb-2">Số tiền cần rút:</p>
                <Input 
                    className="mb-10"
                    placeholder="Nhập số tiền bạn muốn rút" 
                    value={request?.request_money}
                    onChange={(e)=> setRequest((prevState) => ({
                        ...prevState,
                        request_money: e.target.value,
                      }))
                    } 
                />
                <p className="pt-5 pb-2">Nhập số tài khoản - ngân hàng - họ và tên:</p>
                <Input 
                    className="mb-10"
                    placeholder="123456644888 - Vietcombank - Lê Văn A" 
                    value={request?.stk}
                    onChange={(e)=> setRequest((prevState) => ({
                        ...prevState,
                        stk: e.target.value,
                      }))
                    } 
                />
            </Modal>
        </div>
    )
}