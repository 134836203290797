import { useLocation, useNavigate, useParams } from "react-router-dom"
import ImageGallery from "../../component/imageGallery";
import ProductInfor from "../../section/productInfor";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { Avatar, Form, Pagination, message } from "antd";
import parse from 'html-react-parser';
import { getAll, getAllIndexPage } from "../../api/category";
import ItemProduct from "../../section/itemProduct";
import { useDevice } from "../../hooks";
import ProductIsMobile from "./productIsMobile";
import freeship from "../../assets/image/freeship.png";
import { useCookies } from "react-cookie";
import { createAffiliate } from "../../api/product";
import { Input, Button, List, Typography, Rate } from 'antd';
import { FormatVND } from "../../utils/format";

const { TextArea } = Input;
const { Title } = Typography;

export default function Product() {
    const param = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { isMobile } = useDevice();
    const [product, setProduct] = useState([]);
    const [productImage, setProductImage] = useState([]);
    const [isFile, setIsFile] = useState(false);
    const [categoryIndex, setCategoryIndex] = useState([]);
    const [read, setRead] = useState(false);
    const [category, setCategory] = useState([]);
    const [cookies] = useCookies(["user"]);
    const [comments, setComments] = useState([]);
    const [form] = Form.useForm();
    const [pagination, setPagination] = useState({
      page: 1,
      pageSize: 10,
    });  
    
    const fetchProduct = async () => {
        await axiosInstance.get(`/product/getBySlug/${param?.slug}`)
        .then((res) => {
            const data = res?.data?.data;
            setProduct(data);
            const image = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data;
            const slide = JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'slide')?.[0]?.data;
            if(JSON.parse(data[0]?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data) { setIsFile(true) }
            setProductImage([image, ...slide]);
        })
        .catch(() => message.error("Error server!"));
    };

    const getQueryParams = () => {
        const searchParams = new URLSearchParams(location.search);
        return {
            userReferId: searchParams.get('user_refer_id'),
            productId: searchParams.get('product_id'),
        };
    };
    const { userReferId, productId } = getQueryParams();

    const handleCommentSubmit = async (value) => {
        const data = {
            ...value,
            product_id: product?.[0]?.product_id,
            create_by: cookies?.user?.user_id
        }
        await axiosInstance.post(`/comment/create`, data)
        .then((res) => {
            const data = res?.data;
            message.success(String(data?.message));
            form.resetFields();
            getByProductId();
        })
        .catch(() => message.error("Error server!"));
    }

    const getByProductId = async () => {
        await axiosInstance.get(`/comment/getByProductId/` + product?.[0]?.product_id, {params: pagination})
        .then((res) => {
            const data = res?.data;
            setComments(data);
        })
        .catch(() => message.error("Error server!"));
    }

    useEffect(() => {
        if(cookies?.user && userReferId && productId) {
            const fetchProductData = async () => {
                try {   
                    const data = {
                        userReferId: userReferId,
                        userId: cookies?.user?.user_id,
                        product: productId
                    }
                    await createAffiliate(data);
                } catch (error) {
                    message.error(error.message);
                } 
            };
        
            fetchProductData();

        }
    }, [productId, userReferId, cookies]);

    useEffect(() => {
        fetchProduct();
        getAllIndexPage(setCategoryIndex);
    }, [isFile]);

    useEffect(() => {
        getAllIndexPage(setCategoryIndex);
        getAll("page=1&pageSize=100", setCategory);
    }, []);

    useEffect(() => {
        if(product?.[0]?.product_id) {
            getByProductId();
        }
    }, [product]);
    
    return (
        <div className="max-w-screen-2xl mx-auto">
            {isMobile ? <ProductIsMobile /> : (
                <>
                    <div className="flex pt-5">
                        <a href="/">Trang chủ</a> 
                        <p className="px-2">/</p> 
                        <a href={`/danh-muc-san-pham/${product[0]?.category_slug}`}>Danh mục sản phẩm</a>
                        <p className="px-2">/</p> 
                        <a href="">{product[0]?.product_name}</a>
                    </div>
        
                    <div className="grid grid-cols-3 pt-4 gap-2">
                        <div className="bg-white rounded-lg">
                            <ImageGallery product_image={productImage} />
                            <div className="bg-white p-5 ml-2 rounded-lg mt-2">
                                <p className="text-[16px] font-semibold">Đặc điểm nổi bật</p>
                                {parse(String(product[0]?.product_special?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                            </div>
                        </div>
                        <div className="col-span-2 bg-white rounded-lg">
                            <ProductInfor product={product[0]} attribute={product?.attribute} isFile={isFile}/>
                            {product[0]?.price_mkt && (
                                <div className="p-5 flex items-center">
                                    <p className="font-semibold">Giới thiệu bạn bè mua hàng để nhận thưởng: </p>
                                    <button 
                                        type="submit" 
                                        onClick={()=> {
                                            navigate("/kiem-tien/" + product[0]?.product_slug)
                                        }}
                                        className={`${isMobile ? "w-[150px]" : "w-[200px]"} ml-5 text-primary-600 bg-primary-100 border border-primary-600 hover:bg-primary-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium text-[16px] py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}
                                    >
                                        Nhận ngay {FormatVND(product[0]?.product_price * (product[0]?.price_mkt/100))}
                                    </button>
                                </div>
                            )}
                            <div className="p-5">
                                <p className="text-[16px] font-semibold">Thông tin chi tiết</p>
                                {parse(String(product[0]?.product_information?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                            </div>
                        </div>

                        <div className="bg-white p-5 rounded-lg col-span-3">
                            <div>
                                <p className="text-[16px] font-semibold">Chính sách trả hàng</p>
                                <p className="pt-1">Xem ở mục mô tả sản phẩm</p>
                            </div>
                            <div className="py-2">
                                <p className="text-[16px] font-semibold">Vận chuyển</p>
                                <div className="flex items-center pt-1">
                                    <div className="flex items-center">
                                        <img src={freeship} width={30} height={10}/>
                                    </div>
                                    <p className=" pl-2">Vào mục mã giảm giá để lấy mã freeship</p>
                                </div>
                            </div>
                        </div>
                            
                        <div className="bg-white p-5 rounded-lg col-span-3">
                            <p className="text-[16px] font-semibold">Mô tả sản phẩm</p>
                            {read ? (
                                <div>
                                    {parse(String(product[0]?.product_description?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                                </div>
                            ) : (
                                <div className="truncate h-[100px]">
                                    {parse(String(product[0]?.product_description?.replaceAll('ul>',"ul className='list-disc text-start pl-8 py-4'>")))}
                                </div>
                            )}
                            <div className="flex justify-center pt-5">
                                <button className="border border-blue-600 px-4 py-2 text-lg font-semibold text-blue-600" onClick={()=> setRead(!read)}>
                                    {!read ? "Xem thêm" : "Rút gọn"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='my-2 p-5 bg-white rounded-xl'>
                        <div style={{ padding: '20px', borderRadius: '8px', backgroundColor: "white", marginTop: 8, paddingTop: 10 }}>
                            <Title level={4} className="pb-5">Bình luận</Title>
                            {cookies?.user && (
                                <Form form={form} onFinish={handleCommentSubmit}>
                                    <div className="flex w-full">
                                        <div>
                                            <img src={cookies?.user?.photos} width={80} />
                                            <p className="text-lg font-semibold">{cookies?.user?.fullname}</p>
                                        </div>
                                        <div className="w-full pl-5">
                                            <Form.Item
                                                name="comment_content"
                                                className="w-2/3"
                                                rules={[{ required: true, message: 'Vui lòng nhập bình luận!' }]}
                                            >
                                                <TextArea
                                                    rows={4}
                                                    placeholder="Nhập bình luận của bạn..."
                                                    style={{ width: '100%', borderRadius: '4px' }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Form.Item
                                        name="comment_rating"
                                        rules={[{ required: true, message: 'Vui lòng chọn đánh giá!' }]}
                                    >
                                        <Rate style={{ display: 'block' }} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ width: '70%', backgroundColor: '#1890ff', borderColor: '#1890ff' }}
                                        >
                                            <p className="font-bold">Bình luận</p>
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                            <List
                                header={<div className="font-semibold">Danh sách bình luận:</div>}
                                bordered
                                dataSource={comments?.data}
                                renderItem={(item, index) => (
                                    <List.Item key={index} style={{ padding: '10px', borderBottom: '1px solid #eaeaea' }}>
                                        <List.Item.Meta
                                            avatar={
                                                <>
                                                    <img src={item?.photos} width={50} height={50} className="rounded-full"/>
                                                </>
                                            }
                                            title={<span style={{ fontWeight: 'bold' }}>{item.username}</span>}
                                            description={
                                                <div>
                                                    <p className="text-base text-gray-600 font-semibold pb-2">{cookies?.user?.fullname}</p>
                                                    <p><Rate disabled value={item?.comment_rating} style={{ marginRight: '10px' }} /></p>
                                                    <p>{item?.comment_content}</p>
                                                </div>
                                            }
                                        />
                                    </List.Item>
                                )}
                                style={{ marginTop: '20px' }}
                            />
                            <div className="mt-5">
                                <Pagination
                                    className="flex justify-center"
                                    current={pagination.page}
                                    total={comments?.total}
                                    pageSize={pagination.pageSize}
                                    onChange={(p, ps)=> {
                                        setPagination({
                                            page: p,
                                            pageSize: ps
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='my-2 p-5 bg-white rounded-xl'>
                        <div className='flex justify-between'>
                            <div className='flex items-center'>
                                <img src={categoryIndex?.data?.[0]?.category_index_image} className='h-12'/>
                            </div>
                            <a href={`/danh-muc-trang-chu/${categoryIndex?.data?.[0]?.category_index_id}`}>
                                <p className='font-semibold text-[14px] text-[#003EA1]'>Xem tất cả</p>
                            </a>
                        </div>
                        <div className='py-8'>
                            <ItemProduct product={categoryIndex?.data?.[0]?.product} grid={6} titleSize={13} heightTitle={12}/>
                        </div>
                    </div>
        
                    <div className='p-5 bg-white rounded-xl mb-2'>
                        <div className='grid grid-cols-10 gap-4'>
                            {category?.data?.map((_,i)=> (
                                <a href={`/danh-muc-san-pham/${_?.category_slug}`}>
                                    <div className='flex justify-center'>
                                        <img src={_?.category_image} className='rounded-xl h-[40px] w-[40px]'/>
                                    </div>
                                    <p className='text-center pt-1 text-black'>{_?.category_name}</p>
                                </a>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}