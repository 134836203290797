import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react";
import { Col, Menu, Row, message } from "antd";
import { BookOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { useCookies } from "react-cookie";
import { useDevice } from "../../hooks";
import { fetchAllByUser } from "../../api/affiliate";
import RowAffiliate from "../../section/rowAffiliate";

export default function Affiliate() {
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    const [affiliate, setAffiliate] = useState([]);
    const [category_child_id, setCategoryChildId] = useState(0);
    const [paginationBook, setPaginationBook] = useState({
      page: 1,
      pageSize: 5,
    });
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);

    const items = [
        {
          key: 'order',
          label: <a href="/thong-tin-ca-nhan">Đơn hàng của tôi</a>,
          icon: <ShoppingCartOutlined />
        },
        {
          key: 'my-book',
          label: <a href="/sach-cua-toi">Sách của tôi</a>,
          icon: <BookOutlined />
        },
        {
          key: 'affiliate',
          label: <a href="/tiep-thi-lien-ket">Tiếp thị liên kết</a>,
          icon: <BookOutlined />
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    useEffect(() => {
        if(!cookies?.user?.user_id) {
            navigate("/dang-nhap");
            message.info("Bạn vui lòng đăng nhập để được dùng chức năng này!")
        }else {
            const query = `page=${paginationBook?.page}&pageSize=${paginationBook?.pageSize}&user_id=${cookies?.user?.user_id}`;
            fetchAllByUser(query, setAffiliate, message);
        }
    }, [cookies]);

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div className="flex pb-4">
                <a href="">Trang chủ</a> 
                <p className="px-2">/</p> 
                <a href="">Thông tin cá nhân</a>
            </div>

            <Row>
                <Col xs={24} xl={4} className="p-1">
                    <Menu
                        className="w-full"
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={['sub1']}
                        mode="inline"
                        items={items}
                    />
                </Col>
                <Col xs={24} xl={20} className="p-1 bg-white">
                    <RowAffiliate affiliate={affiliate} pagination={paginationBook} setPagination={setPaginationBook} />
                </Col>
            </Row>
        </div>
    )
}