import { Table, message, Button, Row, Col, Pagination, Image, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchProps from "../../../component/SearchProps";
import dayjs from "dayjs";
import axiosInstance from "../../../utils/axios";
import { FormatVND } from "../../../utils/format";

export default function RequestMoneyManage() {
  const [requestMoney, setRequestMoney] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });

  const fetchRequestMoney = async () => {
    await axiosInstance.get(`/affiliate/getAllRequestMoney`, {params: pagination})
      .then((res) => {
        const data = res?.data;
        setRequestMoney(data);
      })
      .catch(() => message.error("Error server!"));
  };

  const update = async (value, id) => {
    await axiosInstance.post(`/affiliate/updateRequestMoney/${id}`, {status: value})
      .then((res) => {
        fetchRequestMoney();
        message.success(String(res?.data?.message));
      });
  }

  useEffect(() => {
    fetchRequestMoney();
  }, [pagination]);

  const optionRole = [
    { value: 0, label: "Đang chờ" },
    { value: 1, label: "Đã thanh toán" },
    { value: 2, label: "Đã từ chối" },
  ];

  const columns = [
    {
      title: <div>ID</div>,
      key: "request_money_id",
      dataIndex: "request_money_id",
      width: 100,
      sorter: (a, b) => a.request_money_id - b.request_money_id,
      render: (_, record) => <div>{record?.request_money_id}</div>,
    },
    {
      title: <div>Số tiền yêu cầu</div>,
      key: "request_money",
      dataIndex: "request_money",
      width: 150,
      render: (_, record) => <div>{FormatVND(record?.request_money)}</div>,
    },
    {
      title: <div>Số tài khoản</div>,
      key: "request_money",
      dataIndex: "request_money",
      width: 150,
      render: (_, record) => <div>{record?.stk}</div>,
    },
    {
      title: <div>Người yêu cầu</div>,
      key: "user_id",
      dataIndex: "user_id",
      width: 150,
      ...SearchProps("fullname"),
      render: (_, record) => <div>{record?.user_id} - {record?.fullname}</div>,
    },
    {
      title: <div className={"base-table-cell-label"}>Trạng thái</div>,
      key: "status",
      dataIndex: "status",
      width: 250,
      filters: [
        { text: 'Đang chờ', value: 0 },
        { text: 'Đã thanh toán', value: 1 },
        { text: 'Đã từ chối', value: 2 },
      ],
      onFilter: (value, record) => {
        return record?.status === value;
      },
      render: (_, record) => {
        return (
          <div>
            <Select
              options={optionRole}
              className={"w-[200px]"}
              value={record?.status === 0 ? "Đang chờ" : record?.status === 1 ? "Đã thanh toán" : "Đã từ chối"}
              defaultValue={record?.status === 0 ? "Đang chờ" : record?.status === 1 ? "Đã thanh toán" : "Đã từ chối"}
              onChange={(value) => update(value, record?.request_money_id)}
            />
          </div>
        );
      },
    },
    {
      title: <div className={"base-table-cell-label "}>Ngày tạo</div>,
      key: "create_at",
      dataIndex: "create_at",
      width: 100,
      sorter: (a, b) => dayjs(a.create_at) - dayjs(b.create_at),
      render: (_, record) => {
        return (
          <div className={"cursor-pointer text-[14px] font-normal"}>
            <span className={"!inline-block min-w-[100px]"}>
              {dayjs(record?.create_at).format("DD/MM/YYYY")}
            </span>
          </div>
        );
      },
    }
  ];

  return (
    <>
      <div>
        <Row gutter={10} className={"mb-[8px]"}>
          <Col flex={1}>
            <div className={"text-[20px] font-medium"}>Quản lý rút tiền</div>
          </Col>
        </Row>
      </div>
      <div className="w-full h-full mt-5 pb-20 relative">
        <Table
          className={"custom-table"}
          dataSource={requestMoney?.data}
          columns={columns}
          pagination={false}
        />
        <Pagination
          className="flex justify-center absolute inset-x-0 bottom-10"
          current={pagination.page}
          total={requestMoney?.total}
          pageSize={pagination.pageSize}
          showSizeChanger
          onChange={(p, ps)=> {
            setPagination({
              page: p,
              pageSize: ps
            })
          }}
        />
      </div>
    </>
  );
}
