import React, { Component } from 'react';
import { Input, Button, Space } from 'antd';

class MultiInput extends Component {
    constructor(props) {
        super(props);
        // Initialize inputs from props or default to an empty array
        this.state = {
            inputs: Array.isArray(props.inputs) ? props.inputs : [''],
        };
    }

    handleChange = (index, value) => {
        const newInputs = [...this.state.inputs];
        newInputs[index] = value; // Update the specific input value
        this.setState({ inputs: newInputs });
        this.props.setInputs(newInputs); // Notify parent component
    };

    addInput = () => {
        this.setState(prevState => {
            const newInputs = [...prevState.inputs, '']; // Add a new empty input
            this.props.setInputs(newInputs); // Notify parent component
            return { inputs: newInputs };
        });
    };

    removeInput = (index) => {
        this.setState(prevState => {
            const newInputs = prevState.inputs.filter((_, i) => i !== index); // Remove the input at the specified index
            this.props.setInputs(newInputs); // Notify parent component
            return { inputs: newInputs };
        });
    };

    render() {
        return (
            <div>
                {this.state.inputs.map((input, index) => (
                    <Space key={index} style={{ marginBottom: 8 }}>
                        <Input 
                            value={input} 
                            onChange={(e) => this.handleChange(index, e.target.value)} 
                            placeholder={`Input ${index + 1}`} 
                        />
                        <Button 
                            type="danger" 
                            onClick={() => this.removeInput(index)} 
                            disabled={this.state.inputs.length === 1} // Disable if only one input
                        >
                            Remove
                        </Button>
                    </Space>
                ))}
                <Button type="dashed" onClick={this.addInput}>
                    Add Input
                </Button>
            </div>
        );
    }
}

export default MultiInput;