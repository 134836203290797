import { Image } from "antd";
import { useState } from "react";
import { useDevice } from "../../hooks";

export default function ImageGallery({product_image}) {
    const { isMobile } = useDevice();
    const [current, setCurrent] = useState(0);

    return (
        <div>
            <div className="flex justify-center py-5">
                <Image src={product_image?.[current]} className={`${isMobile ? "w-full" : "w-[450px] h-[450px]"}`}/>
            </div>
            <div className="flex justify-center pb-5">
                {product_image?.map((_, i) => (
                    <img className={`${i === current ? "border border-blue-500 p-1 rounded-lg" : ""} mx-1`} src={_} width={80} height={80} onMouseOver={()=> setCurrent(i)}/>
                ))}
            </div>
        </div>
    )
}