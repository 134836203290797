import { useNavigate } from "react-router-dom";
import { useDevice } from "../../hooks";
import { Breadcrumb, Col, Pagination, Row } from "antd";
import { useEffect, useState } from "react";
import { getAllBlog } from "../../api/blog";
import ItemBlog from "../../section/itemBlog";

export default function Blog() {
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    const [blog, setBlog] = useState();
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 12,
    });

    useEffect(()=> {        
        const query = `page=${pagination?.page}&pageSize=${pagination?.pageSize}`;
        getAllBlog(query, setBlog);
    }, [pagination])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    return (
        <div className={`max-w-screen-2xl mx-auto my-5 ${isMobile && "pt-14"}`}>
            <div>
                <Breadcrumb
                    items={[
                        {
                            title: 'Trang chủ',
                        },
                        {
                            title: <a href="">Blog</a>,
                        }
                    ]}
                />
            </div>
            <div className="bg-white mt-5 p-10">
                <h2 className="font-bold text-xl border-b">Bài viết mới</h2>
                <div className="py-5">
                    <ItemBlog blog={blog?.data} />
                </div>
                <Pagination
                    className="flex justify-center"
                    current={pagination.page}
                    total={blog?.total}
                    pageSize={pagination.pageSize}
                    
                    onChange={(p, ps)=> {
                        setPagination({
                            page: p,
                            pageSize: ps
                        })
                    }}
                />
            </div>
        </div>  
    )
}