import {
    Form,
    Row,
    Input,
    Space,
    Button,
    message,
    Modal,
  } from "antd";
  import { useEffect, useState } from "react";
  import { Link, useNavigate } from "react-router-dom";
  import { ExclamationCircleOutlined } from "@ant-design/icons";
  import CustomUpload from "../customUpload";
import axiosInstance from "../../utils/axios";
  
  export default function VoucherForm({
    id = "",
    initialValues = {},
    onSubmit = () => {},
  }) {
    const navigate = useNavigate();
    const [form] = Form.useForm();
  
    const deleteVoucher = async () => {
      await axiosInstance.delete(`/voucher/delete/${id}`)
        .then((res) => message.success(String(res?.data?.message)))
    };
  
    useEffect(() => {
      if (Object.keys(initialValues)?.length > 0) {
        form.resetFields();
      }
    }, [form, initialValues]);
  
    const removeVoucher = async () => {
      try {
        await deleteVoucher();
        return navigate("/admin/quan-ly-ma-giam-gia");
      } catch (err) {
        console.log(err.message);
      }
    };
  
    const confirmDeleteBusiness = () => {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        content: "Bạn có chắc chắn xoá mã này?",
        okText: "Xác nhận",
        cancelText: "Huỷ",
        onOk: () => removeVoucher(),
      });
    };
    return (
      <div className={"p-[40px] bg-white rounded-[10px]"}>
        <div className={"!text-[#2d2e32] pb-[10px]"}>
          <Link
            to={"/admin/quan-ly-ma-giam-gia"}
            className={
              "text-[18px] sm:text-[24px] md:text-[26px] xl:text-[26px] font-[500] cursor-pointer "
            }
          >
            {"Thông tin mã giảm giá"}
          </Link>
        </div>
  
        <Form
          layout={"vertical"}
          colon={false}
          form={form}
          initialValues={initialValues}
          onFinishFailed={(e) => console.log(e)}
          onFinish={onSubmit}
        >
          <Form.Item
            label={"Tên"}
            name="voucher_name"
            rules={[{ required: true, message: "Vui lòng nhập tên!" }]}
          >
            <Input size="large" placeholder={"Nhập"} />
          </Form.Item>

          <Form.Item
            label={"Mã giảm giá"}
            name="voucher_code"
            rules={[{ required: true, message: "Vui lòng nhập mã!" }]}
          >
            <Input size="large" placeholder={"Nhập"} />
          </Form.Item>

          <Form.Item
            label={"Giá giảm"}
            name="voucher_price"
            rules={[{ required: true, message: "Vui lòng nhập giá giảm!" }]}
          >
            <Input size="large" placeholder={"Nhập"} />
          </Form.Item>

          <Form.Item
            label={"Giá tối thiểu"}
            name="voucher_level"
            rules={[{ required: true, message: "Vui lòng nhập giá tối thiểu!" }]}
          >
            <Input size="large" placeholder={"Nhập"} />
          </Form.Item>
  
          <Row gutter={40} className={"my-[40px] pl-[20px]"}>
              <Space align="center">
              <Button type={"primary"} htmlType={"submit"}>
                  {id && id !== "tao-moi" ? "Cập nhập" : "Tạo"}
              </Button>
              {id && id !== "tao-moi" && (
                  <Button type={"primary"} danger onClick={confirmDeleteBusiness}>
                      Xoá
                  </Button>
              )}
              </Space>
          </Row>
        </Form>
      </div>
    );
  }
  