import { message } from "antd";
import axiosInstance from "../utils/axios";

export const fetchProduct = async (query, setData, message) => {
    try {
        await axiosInstance.get(`/product/getAll?${query}`)
          .then((res) => {
            const data = res?.data;
            setData(data);
          })
          .catch(() => message.error("Error server!"));
    } catch (error) {
        console.log(error);
    }
};

export const fetchAllProductMkt = async (query, setData, message) => {
  try {
      await axiosInstance.get(`/product/getAllProductMkt?${query}`)
        .then((res) => {
          const data = res?.data;
          setData(data);
        })
        .catch(() => message.error("Error server!"));
  } catch (error) {
      console.log(error);
  }
};

export const createAffiliate = async (data, message) => {
    try {
        await axiosInstance.post(`/affiliate/create`, data)
          .then((res) => {
            const data = res?.data;
            console.log(data);
          })
          .catch(() => message.error("Error server!"));
    } catch (error) {
        console.log(error);
    }
};
