import {
  Form,
  Row,
  Input,
  Space,
  Button,
  message,
  Modal,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import CustomSunEditor from "../../../component/customSunEditor";
import MultiInput from "../../../component/multiInput";

export default function EmailMkt() {
  const navigate = useNavigate();
  const [editorValue, setEditorValue] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); // Add loading state

  const onSubmit = async (value) => {
      setLoading(true); // Set loading to true
      try {
          const res = await axiosInstance.post(`/emailMkt/create`, value);
          message.success(String(res?.data?.message));
          form.resetFields();
      } catch (error) {
          message.error("Đã xảy ra lỗi, vui lòng thử lại!"); // Handle error
      } finally {
          setLoading(false); // Reset loading state
      }
  };

  return (
      <div className={"p-[40px] bg-white rounded-[10px]"}>
          <div className={"!text-[#2d2e32] pb-[50px]"}>
              <Link
                  to={"/admin/quan-ly-blogc"}
                  className={
                      "text-[18px] sm:text-[24px] md:text-[26px] xl:text-[26px] font-[500] cursor-pointer "
                  }
              >
                  {"Gửi mail marketing"}
              </Link>
          </div>

          <Form
              layout={"vertical"}
              colon={false}
              form={form}
              initialValues={initialValues}
              onFinishFailed={(e) => console.log(e)}
              onFinish={onSubmit}
          >
              <Form.Item
                  label={"Tiêu đề"}
                  name="title"
                  rules={[{ required: true, message: "Vui lòng nhập tiêu đề!" }]}
              >
                  <Input size="large" placeholder={"Nhập"} />
              </Form.Item>

              <Form.Item
                  label={"Nội dung"}
                  name="contentHtml"
                  rules={[{ required: true, message: "Vui lòng nhập nội dung!" }]}
              >
                  <CustomSunEditor value={editorValue} onChange={(e) => setEditorValue(e)} />
              </Form.Item>

              <Row gutter={40} className={"my-[40px] pl-[20px]"}>
                  <Space align="center">
                      <Button type={"primary"} htmlType={"submit"} loading={loading} disabled={loading}>
                          Gửi
                      </Button>
                  </Space>
              </Row>
          </Form>
      </div>
  );
}