import { Rate, message } from "antd";
import { FormatVND } from "../../utils/format";
import { useDevice } from "../../hooks";
import giaohang from "../../assets/image/giaohang.png"
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function ItemProductGrid({
    product, 
    grid, 
    titleSize,
    heightTitle
}) {
    const { isMobile } = useDevice();
    const location = useLocation();
    const navigate = useNavigate();
    const [cookies] = useCookies(["user"]);

    return (
        <div className={`grid grid-cols-${grid} gap-4`}>
            {product?.map((_, i) => (
                <>
                    <div className="border rounded-lg">
                        <div className="flex justify-center items-center">
                            <img src={JSON.parse(_?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data} className={`w-full ${isMobile ? "h-[w-full]" : "h-[230px]"}`}/>
                        </div>
                        <div className="p-2">
                            <a href={`${location.pathname.includes("/kiem-tien") ? "/kiem-tien/" + _?.product_slug : "/san-pham/" + _?.product_slug } `}>
                                <h3 className={`line-clamp-2 text-black font-medium text-[${titleSize}px] h-${heightTitle}`}>{_?.product_name}</h3>
                                {isMobile ? (
                                    <div>
                                        <Rate style={{fontSize: 16}} allowHalf defaultValue={5} disabled/>
                                        <p className="text-[#808089] text-[10px] font-normal">Đã bán: {100 + Number(_?.product_sale)}</p>
                                    </div>
                                ) : (
                                    <div className="flex items-center py-1">
                                        <Rate style={{fontSize: 16}} allowHalf defaultValue={5} disabled/>
                                        <p className="pl-2 ml-2 border-l text-[#808089] text-[14px] font-normal">Đã bán: {100 + Number(_?.product_sale)}</p>
                                    </div>
                                )}
                                <div className="py-2">
                                    {_?.product_discount ? (
                                        <div className="flex items-start justify-between">
                                            <div>
                                                <p className="text-[#FF424E] font-semibold text-[16px]">{FormatVND(_?.product_price - (_?.product_price * (_?.product_discount/100)))}</p>
                                                <div className="flex items-center">
                                                    <p className="bg-[#f5f5fa] p-1 rounded-lg text-[12px] font-semibold">-{_?.product_discount}%</p>
                                                    <p className="text-[#808089] text-[10px] pl-2 line-through">{FormatVND(_?.product_price)}</p>
                                                </div>
                                            </div>
                                            {_?.price_mkt > 0 && (
                                                <p className="bg-[#f5f5fa] p-1 rounded-lg text-[10px] font-semibold">Hoa hồng: +{_?.price_mkt}%</p>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="flex items-start justify-between">
                                            <div>
                                                <p className="text-[#FF424E] font-semibold text-[16px]">{FormatVND(_?.product_price)}</p>
                                                <div className="flex items-center">
                                                    <p className="bg-[#f5f5fa] p-1 rounded-lg text-[12px] font-semibold">- {_?.product_discount}%</p>
                                                </div>
                                            </div>
                                            <p className="bg-[#f5f5fa] p-1 rounded-lg text-[10px] font-semibold">Hoa hồng: + {_?.price_mkt}%</p>
                                        </div>
                                    )}
                                </div>
                            </a>
                            <div className="flex border-t pt-2 mt-2">
                                {location.pathname.includes("/kiem-tien") ? (
                                    <div className="w-full">
                                        {_?.price_mkt > 0 && (
                                            <div className="flex items-center justify-center w-full">
                                                <p 
                                                    className={`${isMobile ? "text-[10px]" : "text-[13px]"} bg-red-500 px-2 py-1 rounded-lg text-white font-semibold cursor-pointer`}
                                                    onClick={()=>{
                                                        navigate("/kiem-tien/" + _?.product_slug);
                                                    }}    
                                                >
                                                    Nhận ngay: {FormatVND(_?.product_price * (_?.price_mkt/100))}
                                                </p>
                                            </div>
                                        )}
                                        {/* {_?.price_mkt > 0 && (
                                            <div className="px-5 pt-2 ml-2">
                                                <p className="text-[16px] font-semibold">Link tiếp thị:</p>
                                                <p>https://cuahangykhoa.com/san-pham/{_?.product_slug}?user_id={cookies?.user?.user_id}</p>
                                            </div>
                                        )} */}
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        {!JSON.parse(_?.product_image)?.filter((i) => i.type === 'file')?.[0]?.data ? (
                                            <img src={giaohang} className={`${isMobile ? "h-3" : "h-5"}`}/>
                                        ): (
                                            <img src="https://salt.tikicdn.com/ts/tka/a8/31/b6/802e2c99dcce64c67aa2648edb15dd25.png" className={`${isMobile ? "h-3" : "h-5"}`}/>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            ))}
        </div>
    )
}