import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import { message } from "antd";
import { useCookies } from "react-cookie";
import { useDevice } from "../../hooks";
import { FormatVND } from "../../utils/format";
import ImageGallery from "../../component/imageGallery";
import coin from "../../assets/icon/coin.png"

export default function InstructionMoney() {
    const param = useParams();
    const { isMobile } = useDevice();
    const navigate = useNavigate();
    const [product, setProduct] = useState();
    const [productImage, setProductImage] = useState();
    const [cookies] = useCookies(["user"]);
    
    const fetchProduct = async () => {
        await axiosInstance.get(`/product/getBySlug/${param?.slug}`)
        .then((res) => {
            const data = res?.data?.data;
            setProduct(data);
            const image = JSON.parse(data?.[0]?.product_image)?.filter((i) => i.type === 'image')?.[0]?.data;
            const slide = JSON.parse(data?.[0]?.product_image)?.filter((i) => i.type === 'slide')?.[0]?.data;
            setProductImage([image, ...slide]);
        })
        .catch(() => message.error("Error server!"));
    };

    const handleFacebookShare = () => {
        const shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`https://cuahangykhoa.com/san-pham/${product?.[0]?.product_slug}?user_refer_id=${cookies?.user?.user_id}&product_id=${product?.[0]?.product_id}`)}`;
        window.open(shareLink, '_blank');
    };

    useEffect (() => {
        fetchProduct();
    }, []);
    
    useEffect (() => {
        if(!cookies?.user) {
            navigate("/dang-nhap");
            message.warning("Bạn vui lòng đăng nhập để được sử dụng chức năng này!");
        }
    }, [cookies]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    return (
        <div className={`max-w-screen-2xl mx-auto ${isMobile ? "mt-[40px]" : ""}`}>
            
            <div className="bg-white pb-5 my-2 rounded-lg">
                <div>
                    <ImageGallery product_image={productImage} />
                </div>
                <p className="font-bold text-xl px-5">{product?.[0]?.product_name}</p>
            </div>

            <div className="bg-white p-5 my-2 rounded-lg">
                <p className="font-bold text-xl">Hướng dẫn kiếm hoa hồng từ việc chia sẻ sản phẩm:</p>
                <ul className="text-lg list-disc p-5"> 
                    <li>Bước 1: Nhấn vào nút "Chia sẻ ngay" bên dưới để sao chép link sản phẩm.</li>
                    <li>Bước 2: Gửi link sản phẩm cho bạn bè, người quen qua mạng xã hội, tin nhắn, hoặc email...</li>
                    <li>Bước 3: Khi họ nhấp vào link và mua hàng thành công (không đổi trả), bạn sẽ nhận được hoa hồng.</li>
                    <li>Bước 4: Rút tiền về tài khoản vào ngày 10 và ngày 20 hàng tháng.</li>
                </ul>
                <p className="text-lg">Nhanh tay chia sẻ ngay để tăng thu nhập!</p>
            </div>
            
            <div className="bg-white p-5 my-2 rounded-lg">
                <p className="font-bold text-xl pb-2">Nội dung: </p>
                <div className="border rounded p-2 text-lg font-medium">
                    <p>Deal siêu hời trên cuahangykhoa.com</p>
                    <p>Tên sản phẩm: {product?.[0]?.product_name}</p>
                    <p>Giá gốc: {FormatVND(product?.[0]?.product_price)}</p>
                    <p>Giá đã giảm: {FormatVND(product?.[0]?.product_price - (product?.[0]?.product_price * (product?.[0]?.product_discount / 100)))}</p>
                    <u className="text-lg font-semibold">Link sản phẩm:</u>
                    {isMobile && <br />}
                    <a 
                        className={`text-blue-600 font-semibold ${isMobile ? "" : "pl-2"}`} 
                        href={`https://cuahangykhoa.com/san-pham/${product?.[0]?.product_slug}?user_refer_id=${cookies?.user?.user_id}&product_id=${product?.[0]?.product_id}`}
                    >
                        {`https://cuahangykhoa.com/san-pham/${product?.[0]?.product_slug}?user_refer_id=${cookies?.user?.user_id}&product_id=${product?.[0]?.product_id}`}
                    </a>
                </div>
                <div className={`${isMobile ? "" : "flex justify-center"}`}>
                    <p 
                        className={`${isMobile ? "text-[18px]" : "text-[24px] w-1/2"} bg-red-500 p-2 my-5 rounded-lg text-white text-center font-semibold cursor-pointer`}
                        onClick={() => {
                            const productLink = `https://cuahangykhoa.com/san-pham/${product?.[0]?.product_slug}?user_refer_id=${cookies?.user?.user_id}&product_id=${product?.[0]?.product_id}`;
                            const productInfo = `
                                Deal siêu hời trên cuahangykhoa.com
                                Tên sản phẩm: ${product?.[0]?.product_name}
                                Giá gốc: ${FormatVND(product?.[0]?.product_price)}
                                Giá đã giảm: ${FormatVND(product?.[0]?.product_price - (product?.[0]?.product_price * (product?.[0]?.product_discount / 100)))}
                                Link sản phẩm: ${productLink}
                            `;
                            navigator.clipboard.writeText(productInfo);
                            message.info("Sao chép thành công!");
                        }}    
                    >
                        Sao chép
                    </p>
                </div>
            </div>

            <div className={`bg-white px-5 py-2 rounded-lg ${isMobile ? "fixed bottom-12 left-0 right-0 z-10" : "mb-10" }`}>                
                <div className={`flex justify-between items-center`}>
                    <p className={`flex items-center text-red-500 font-bold ${isMobile ? "text-lg" : "text-xl"}`}>
                        <img src={coin} width={isMobile ? 30 : 50 }/> 
                        <div className="pl-2">
                            <u>Nhận ngay: </u>
                            <p>{FormatVND(product?.[0]?.product_price * (product?.[0]?.price_mkt/100))}</p>
                        </div>
                    </p>
                    <p 
                        className={`${isMobile ? "text-[14px] w-2/5" : "text-[24px] w-1/2"} bg-red-500 p-2 my-5 rounded-full text-white text-center font-semibold cursor-pointer`}
                        onClick={()=>{
                            navigator.clipboard.writeText(`https://cuahangykhoa.com/san-pham/` + product?.[0]?.product_slug + "?user_refer_id=" + cookies?.user?.user_id + "&product_id=" + product?.[0]?.product_id);
                            message.info("Bạn đã copy link tiếp thị thành công!")}
                        }    
                    >
                        Copy link tiếp thị
                    </p>
                </div>
            </div>
        </div>
    )
}